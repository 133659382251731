import {
  type ListMode,
  type PrismaClient,
  type PrismaTXN,
  type GetMongoDriverReturnType,
  type ObjectIdType,
  type UserRepoSummary,
  type CommitsByDay,
  type ContactBatchCreationMethod,
} from "@openqlabs/drm-db";
export * as Rest from "./rest";
export * as Constants from "./constants";

import {
  getEvaluationTopic,
  getFourWeeksAgoIso,
  getSixWeeksAgoIso,
  REPO_NOT_ADDED_REASONS,
} from "./constants";
export * as Types from "./types";
import { GitguruCommit } from "./graphql/types";
export { Logger } from "./logger";
export * as GithubGraphql from "./graphql";
export * as Authentication from "./authenticationManager";
export * as Kafka from "./kafka";
export * as KafkaConstants from "./kafkaConstants";
import * as Enums from "./enums";
export * as Enums from "./enums";
import upsertListEvaluation from "./upsertListEvaluation";
import upsertOverviewEvaluation from "./upsertOverviewEvaluation";
import type { KafkaSingleton } from "./kafka";

export const defaultFileNames = [
  "Pipfile",
  "requirements.txt",
  "package.json",
  "go.mod",
  "pom.xml",
  "build.gradle",
  "Gemfile",
  "Cargo.toml",
  ".cabal",
  "composer.json",
  "hardhat.config",
  "truffle",
  "network",
  "deployments",
  "foundry.toml",
];

export const formatTimeTable = (time: number | undefined | null) => {
  if (!time) return "";
  return new Date(time).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });
};

export const getListStartDate = (
  list: {
    mode: ListMode | null;
    startDate: Date;
  } | null
) => {
  return list?.mode === Enums.ListMode.DEEP
    ? list?.startDate.toISOString()
    : list?.mode === Enums.ListMode.LIGHT
      ? getSixWeeksAgoIso()
      : getFourWeeksAgoIso();
};

export function filterUnwantedAuthors(commit: GitguruCommit) {
  return (
    !commit.author_email.includes("[bot]") &&
    commit.author_email !== "action@github.com" &&
    !commit.author.includes("[bot]") &&
    commit.github_graphql_id &&
    commit.github_graphql_id !== ""
  );
}

export function getCurrentTimestamp() {
  return new Date().toISOString();
}

export const getFirstDayOfMonthISO = (yearMonth: string): string => {
  const [year, month] = yearMonth.split("-");
  return new Date(`${year}-${month}-01`).toISOString();
};

export const getFirstDayOfNextMonthISO = (yearMonth: string): string => {
  const [year, month] = yearMonth.split("-");
  const nextMonth = parseInt(month) === 12 ? 1 : parseInt(month) + 1;
  const nextYear = nextMonth === 1 ? parseInt(year) + 1 : parseInt(year);
  return new Date(nextYear, nextMonth - 1, 1).toISOString();
};

export const createOrUpdateTeamEvals = async (
  prisma: PrismaClient | PrismaTXN,
  params: {
    teamAccountId: string;
    listId: string;
  }
) => {
  await upsertListEvaluation(prisma, params.listId, params.teamAccountId);
  await upsertOverviewEvaluation(prisma, params.teamAccountId);
};

export const errorToString = (error: Error | undefined) => {
  let errorMessage = null;
  if (error) {
    if (typeof error === "string") {
      errorMessage = error;
    } else if (typeof error === "object") {
      if ("message" in error) {
        errorMessage = error.message;
      } else {
        errorMessage = JSON.stringify(error);
      }
    }
  }
  return errorMessage;
};

export const encodeStatusAsInt = (
  status: Statuses,
  mode: ListMode | null
): number => {
  if (mode == Enums.ListMode.DEEP) {
    switch (status) {
      // status numbers for list in deep mode
      case "lead":
        return 1;
      case "new lead":
        return 2;
      case "customer":
        return 3;
      case "lead inactive":
        return 4;
      case "new lead inactive":
        return 5;
      case "customer inactive":
        return 6;
      case "lead churned":
        return 7;
      case "new lead churned":
        return 8;
      case "customer churned":
        return 9;
      case "inactive":
        return 10;
      case "churned":
        return 11;
      case "cold":
        return 12;
    }
  } else if (mode == Enums.ListMode.LIGHT) {
    // status numbers for list in light mode
    switch (status) {
      case "lead":
      case "new lead":
      case "customer":
        return 13;
      case "lead inactive":
      case "new lead inactive":
      case "customer inactive":
      case "inactive":
      case "cold":
        return 14;
      case "lead churned":
      case "new lead churned":
      case "customer churned":
      case "churned":
        return 15;
    }
  } else if (mode == Enums.ListMode.ULTRALIGHT) {
    // status numbers for list in ultra light mode
    switch (status) {
      case "lead":
      case "new lead":
      case "customer":
        return 13;
      case "lead inactive":
      case "new lead inactive":
      case "customer inactive":
      case "inactive":
      case "cold":
        return 14;
      case "lead churned":
      case "new lead churned":
      case "customer churned":
      case "churned":
        return 15;
    }
  } else {
    throw new Error(`UNKNOWN LIST MODE!: ${mode}`);
  }
};

export type Statuses =
  | "lead"
  | "new lead"
  | "customer"
  | "lead inactive"
  | "new lead inactive"
  | "customer inactive"
  | "lead churned"
  | "new lead churned"
  | "customer churned"
  | "inactive"
  | "churned"
  | "cold";

const getTooltip = (status: string, lightMode?: boolean) => {
  // note that lightMode only makes a difference for a user's status
  if (!lightMode) {
    switch (status) {
      case "lead":
        return "Lead generated during list";
      case "new lead":
        return "Lead generated post list";
      case "lead inactive":
        return "New lead but inactive for 6 weeks";
      case "new lead inactive":
        return "Lead generated post list but inactive for 6 weeks";
      case "lead churned":
        return "New lead but dependency was removed";
      case "new lead churned":
        return "Lead generated post list but dependency was removed";
      case "customer":
        return "";
      case "customer inactive":
        return "Returning customer but inactive for 6 weeks";
      case "customer churned":
        return "Returning customer but dependency was removed";
      case "churned":
        return "Customer churned before list start";
      case "inactive":
        return "Customer inactive since list start";
      case "cold":
        return "";

      default:
        return "No evaluation result available yet";
    }
  } else {
    switch (status) {
      // in Light mode we only analyze the last 6 weeks for user dependencies
      // hence we can only know if users are active/inactive/churned
      // NB: repo-dependencies can still be shown in detail aka deep mode

      case "active":
        return "User active over the past 6 weeks";
      case "inactive":
        return "User inactive over the past 6 weeks";
      case "churned":
        return "User churned over the past 6 weeks";

      default:
        return "No dependency evaluation result available yet";
    }
  }
};

// decode status as object with string and color and tooltip
export const decodeStatusAsObject = (
  status: number | null | undefined,
  manualStatus: number | null | undefined
) => {
  const manualStatusTooltip = manualStatus ? " (manual)" : "";
  switch (status) {
    case 1:
      return {
        status: "lead",
        color: "bg-new",
        tooltip: getTooltip("lead") + manualStatusTooltip,
      };
    case 2:
      return {
        status: "new lead",
        color: "bg-new",
        tooltip: getTooltip("new lead") + manualStatusTooltip,
      };
    case 3:
      return {
        status: "customer",
        color: "bg-customer",
        tooltip: getTooltip("customer") + manualStatusTooltip,
      };
    case 4:
      return {
        status: "lead 😴",
        color: "bg-new",
        tooltip: getTooltip("lead inactive") + manualStatusTooltip,
      };
    case 5:
      return {
        status: "new lead 😴",
        color: "bg-new",
        tooltip: getTooltip("new lead inactive") + manualStatusTooltip,
      };
    case 6:
      return {
        status: "customer 😴",
        color: "bg-customer",
        tooltip: getTooltip("customer inactive") + manualStatusTooltip,
      };
    case 7:
      return {
        status: "lead ❌",
        color: "bg-churned",
        tooltip: getTooltip("lead churned") + manualStatusTooltip,
      };
    case 8:
      return {
        status: "new lead ❌",
        color: "bg-churned",
        tooltip: getTooltip("new lead churned") + manualStatusTooltip,
      };
    case 9:
      return {
        status: "customer ❌",
        color: "bg-churned",
        tooltip: getTooltip("customer churned") + manualStatusTooltip,
      };
    case 10:
      return {
        status: "inactive",
        color: "bg-blue-200",
        tooltip: getTooltip("inactive") + manualStatusTooltip,
      };
    case 11:
      return {
        status: "churned",
        color: "bg-churned",
        tooltip: getTooltip("churned") + manualStatusTooltip,
      };
    case 12:
      return {
        status: "cold",
        color: "bg-cold",
        tooltip: getTooltip("cold") + manualStatusTooltip,
      };
    case 13:
      return {
        status: "active",
        color: "bg-customer",
        tooltip: getTooltip("active", true) + manualStatusTooltip,
      };
    case 14:
      return {
        status: "inactive",
        color: "bg-blue-200",
        tooltip: getTooltip("inactive", true) + manualStatusTooltip,
      };
    case 15:
      return {
        status: "churned",
        color: "bg-churned",
        tooltip: getTooltip("churned", true) + manualStatusTooltip,
      };
    default:
      return {
        status: "no status",
        color: "bg-offwhite",
        tooltip: getTooltip("no status"),
      };
  }
};

export const pluralize = (word: string, count: number) => {
  if (count === 1) return word;
  const lastLetter = word[word.length - 1];
  if (lastLetter === "y") return word.slice(0, -1) + "ies";
  return word + "s";
};

export const readableErrorFromScreamingSnake = (error: string) => {
  return error
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export function kbToMb(kb: number) {
  return Math.floor(kb / 1024);
}

export const getGithubName = (url?: string) => {
  if (!url) return "";

  const result = url.replace(/^https:\/\/github\.com\//, "").replace(/\/$/, "");
  return result;
};

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const asyncForEach = async <T>(
  array: T[],
  callback: (item: T, index: number, array: T[]) => Promise<void>
) => {
  for (let index = 0; index < array.length; index++) {
    const item = array[index];
    await callback(item, index, array);
  }
};

export const refetchHandler = (
  data: { status: string }[] | undefined,
  length?: number
): number | false => {
  if (
    data?.some(
      (elem) => elem.status === "waiting" || (length && data.length < length)
    )
  ) {
    return 60 * 60 * 1000;
  }
  return false;
};

export const getReadableDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const getReadableDateTime = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

export const getMonthDistance = (endDate: Date, startDate?: Date) => {
  if (!startDate) return 0;
  const years = endDate.getFullYear() - startDate.getFullYear();
  const months = endDate.getMonth() - startDate.getMonth();
  let totalMonths = years * 12 + months;

  if (endDate.getDate() < startDate.getDate()) {
    totalMonths--;
  }

  return totalMonths;
};
export function nukeNegatives(val?: number | string | null) {
  if (!val) return 0;
  if (typeof val === "string") val = parseFloat(val);
  if (val < 0) return 0;
  if (Number.isNaN(val)) return 0;
  else return Math.round(val);
}

export const maxOne = (score: number | null) => {
  if (score === null) return 0;
  if (score > 1) return 1;
  return score;
};

export const getMonthFromNumber = (monthNumber: number) => {
  switch (monthNumber) {
    case 0:
      return "January";
    case 1:
      return "February";
    case 2:
      return "March";
    case 3:
      return "April";
    case 4:
      return "May";
    case 5:
      return "June";
    case 6:
      return "July";
    case 7:
      return "August";
    case 8:
      return "September";
    case 9:
      return "October";
    case 10:
      return "November";
    case 11:
      return "December";
    default:
      return "January";
  }
};

export const getUniqueArray = <T extends { id: string }>(array: T[]): T[] =>
  array.reduce<T[]>((accumulator, current) => {
    const existingItem = accumulator.find((item) => item.id === current.id);
    if (!existingItem) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
export const getUniqueArraySimple = <T>(array: T[]): T[] =>
  array.reduce<T[]>((accumulator, current) => {
    const existingItem = accumulator.includes(current);
    if (!existingItem) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

export const getRank = (daysActive: number) => {
  switch (true) {
    case daysActive === 0:
      return "inactive";
    case daysActive === 1:
      return "one-time";
    case daysActive >= 10:
      return "full-time";
    default:
      return "part-time";
  }
};

export const getContactCreationCreditsCost = (
  method: ContactBatchCreationMethod
) => {
  switch (method) {
    case Enums.ContactBatchCreationMethod.ADD:
    case Enums.ContactBatchCreationMethod.IMPORT:
      return 1;
    case Enums.ContactBatchCreationMethod.LEAD_DISCOVERY:
    case Enums.ContactBatchCreationMethod.FORKS_DISCOVERY:
      return 0.2;
    case Enums.ContactBatchCreationMethod.ADD_CONTACTS_BY_LIST:
    case Enums.ContactBatchCreationMethod.BOOST:
      return 0;
    default:
      return 0;
  }
};

export const getContactCreationMethod = (headerMenu: string) => {
  switch (headerMenu) {
    case "Add":
      return Enums.ContactBatchCreationMethod.ADD;
    case "Import":
      return Enums.ContactBatchCreationMethod.IMPORT;
    case "Lead Discovery":
      return Enums.ContactBatchCreationMethod.LEAD_DISCOVERY;
    case "Forks Discovery":
      return Enums.ContactBatchCreationMethod.FORKS_DISCOVERY;
    case "Add Contacts By List":
      return Enums.ContactBatchCreationMethod.ADD_CONTACTS_BY_LIST;
    case "Boost":
      return Enums.ContactBatchCreationMethod.BOOST;
    default:
      return undefined;
  }
};

export const getTotalUsedCredits = async (
  teamAccountId: string,
  prisma: PrismaClient
) => {
  const usedCredits = await prisma.listContact.aggregate({
    where: { teamAccountId: teamAccountId, creditsCost: { not: null, gt: 0 } },
    _sum: { creditsCost: true },
  });
  return usedCredits._sum.creditsCost ?? 0;
};
export const getDayOnlyIso = () => {
  return new Date().toISOString().slice(0, 10);
};

export const runTeamAccountCreditsCheck = async (
  teamAccountId: string,
  prisma: PrismaClient
) => {
  console.log("Checking credits for team account", teamAccountId);
  const totalUsedCredits = await getTotalUsedCredits(teamAccountId, prisma);

  const teamAccount = await prisma.teamAccount.findUnique({
    where: { id: teamAccountId },
    include: { stripeSubscription: true },
  });

  const totalContactsCredits =
    teamAccount?.stripeSubscription?.totalContacts ?? 0;

  if (totalContactsCredits <= totalUsedCredits) {
    throw new Error("Insufficient credits");
  }
};

export const isInRepoNotAddedReasons = (error: string): boolean => {
  return REPO_NOT_ADDED_REASONS.includes(error);
};

export const runOldSideEffectsAndSendMessages = async (
  mongo: GetMongoDriverReturnType, // Updated the type to MongoClient instead of MongoClientType
  prisma: PrismaClient,
  listId: string,
  teamAccountId: string,
  depsOnly = false,
  kafkaSingleton: KafkaSingleton,
  resetEvalInfo: boolean,
  listIdBSON?: ObjectIdType
) => {
  const hasTracker = await prisma.tracker.findFirst({
    where: {
      listId: listId,
    },
  });
  const waitingOnDepsEval = !!hasTracker;
  const waitingOnEval = !depsOnly;
  const dependencyStatusNumber = waitingOnDepsEval ? 100 : 0;
  const optionalTypeFilter = depsOnly
    ? { type: { $in: ["user-dependencies", "repo-dependencies"] } }
    : {};

  const evaluations = await mongo
    .collection("Evaluation")
    .find({ listId: listIdBSON, ...optionalTypeFilter })
    .toArray();
  console.log("Reevaluateing evaluations", evaluations.length);
  if (resetEvalInfo) {
    await mongo.collection("ListContact").updateMany(
      { listId: listIdBSON },
      {
        $set: { waitingOnEval, waitingOnDepsEval, dependencyStatusNumber },
      }
    );
  }
  for (const evaluation of evaluations) {
    if (!evaluation?.queued)
      await kafkaSingleton
        .publishMessage(getEvaluationTopic(evaluation.type) as string, {
          evaluationId: evaluation._id.toString(),
          teamAccountId: teamAccountId,
          listId: listId,
          type: evaluation.type,
        })
        .then(async () => {
          console.info(
            `Successfully published evaluation ${evaluation._id.toString()}. Updating status...`
          );
          return await prisma.evaluation.update({
            where: { id: evaluation._id.toString() },
            data: { queued: true },
          });
        })
        .then(() => {
          console.info(
            `Evaluation ${evaluation._id.toString()} status updated to queued.`
          );
        })
        .catch((err) => {
          console.error(
            `Error publishing evaluation ${evaluation._id.toString()} to topic: ${err}`
          );
        });
  }
  await prisma.listEvaluation.update({
    where: {
      listId_teamAccountId: {
        listId: listId,
        teamAccountId: teamAccountId,
      },
    },
    data: { status: "waiting", error: null },
  });

  await prisma.overviewEvaluation.upsert({
    where: { teamAccountId },
    update: { status: "waiting", error: null },
    create: { teamAccountId, status: "waiting", error: null },
  });
};

export function getAllMonths(userRepoSummary: UserRepoSummary[]): string[] {
  let oldestMonth = new Date();
  let newestMonth = new Date();
  userRepoSummary.forEach((repo) => {
    repo.userActivityWithUniqueMonth.forEach((monthlyActivity) => {
      if (new Date(monthlyActivity.month) < oldestMonth) {
        oldestMonth = new Date(monthlyActivity.month);
      }
      if (new Date(monthlyActivity.month) > newestMonth) {
        newestMonth = new Date(monthlyActivity.month);
      }
    });
  });

  const allMonthsInRange = [];
  const currentMonth = new Date(oldestMonth);

  while (currentMonth <= newestMonth) {
    allMonthsInRange.push(currentMonth.toISOString().slice(0, 7));
    currentMonth.setMonth(currentMonth.getMonth() + 1);
  }

  return allMonthsInRange;
}

export const getLatestCommitDate = (commitsByDay?: CommitsByDay) => {
  if (commitsByDay) {
    const sortedCommitsByDay = Object.keys(commitsByDay).sort((a, b) => {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    const latestDateBasic = sortedCommitsByDay[sortedCommitsByDay.length - 1];

    return new Date(latestDateBasic).getTime();
  } else return 0;
};
