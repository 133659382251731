"use client";

import { useState } from "react";
import { Badge } from "~/components/ui/badge";
import { Switch } from "~/components/ui/switch";
import { Label } from "~/components/ui/label";
import { api, RouterOutputs } from "~/server/trpc/react";
import { UpdateActivePlanConfirmationModal } from "./UpdateActivePlanConfirmationModal";
import DeleteContactsModal from "./DeleteContactsModal";
import { NeedAdminForUpgradeModal } from "./NeedAdminForUpgradeModal";
import { useSubscriptionManagement } from "./hooks/useSubscriptionManagement";
import PricingCard from "./PricingCard";
import { Skeleton } from "../ui/skeleton";

/**
 * Scans productPrices in one pass to gather specific plan/interval combinations:
 * - Basic Plan (monthly/yearly)
 * - Advanced Plan (monthly/yearly)
 * - Enterprise Plan
 */
function gatherSubscriptionPlans(
  productPrices: RouterOutputs["stripe"]["getProductPrices"]
) {
  let basicMonth = null;
  let basicYear = null;
  let advancedMonth = null;
  let advancedYear = null;
  let enterprisePlan = null;
  for (const product of productPrices) {
    if (
      basicMonth &&
      basicYear &&
      advancedMonth &&
      advancedYear &&
      enterprisePlan
    ) {
      break;
    }

    // If "Basic Plan", look for both monthly & yearly
    if (product.name === "Basic Plan") {
      const monthlyPrice = product.prices.find(
        (price) => price.pricingPlanInterval === "month"
      );
      if (monthlyPrice) {
        basicMonth = {
          ...product,
          price: monthlyPrice,
        };
      }

      const yearlyPrice = product.prices.find(
        (price) => price.pricingPlanInterval === "year"
      );
      if (yearlyPrice) {
        basicYear = {
          ...product,
          price: yearlyPrice,
        };
      }
    }
    // If "Advanced Plan", look for both monthly & yearly
    else if (product.name === "Advanced Plan") {
      const monthlyPrice = product.prices.find(
        (price) => price.pricingPlanInterval === "month"
      );
      if (monthlyPrice) {
        advancedMonth = {
          ...product,
          price: monthlyPrice,
        };
      }

      const yearlyPrice = product.prices.find(
        (price) => price.pricingPlanInterval === "year"
      );
      if (yearlyPrice) {
        advancedYear = {
          ...product,
          price: yearlyPrice,
        };
      }
    } else if (product.name === "Enterprise Plan") {
      enterprisePlan = {
        ...product,
        price: product.prices[0],
      };
    }
  }

  return {
    stripePriceBasicMonthPlan: basicMonth,
    stripePriceBasicYearPlan: basicYear,
    stripePriceAdvancedMonthPlan: advancedMonth,
    stripePriceAdvancedYearPlan: advancedYear,
    stripePriceEnterprisePlan: enterprisePlan,
  };
}

export default function SubscriptionPlans() {
  const {
    activeSubscriptionPlan,
    isUpdatingPlan,
    choicedPlanPriceId,
    setChoicedPlanPriceId,
    showDeleteContactsModal,
    setShowDeleteContactsModal,
    showNeedAdminModal,
    setShowNeedAdminModal,
    showConfirmationPlanModal,
    setShowConfirmationPlanModal,
    confirmChangesData,
    handleSubscriptionUpdate,
    handleGoToSubscriptionCheckoutSession,
  } = useSubscriptionManagement();

  const [isAnnual, setIsAnnual] = useState(false);

  const { data: productPrices = [], isLoading: isLoadingProductPrices } =
    api.stripe.getProductPrices.useQuery<
      RouterOutputs["stripe"]["getProductPrices"]
    >({});

  const {
    stripePriceBasicMonthPlan,
    stripePriceBasicYearPlan,
    stripePriceAdvancedMonthPlan,
    stripePriceAdvancedYearPlan,
    stripePriceEnterprisePlan,
  } = gatherSubscriptionPlans(productPrices);

  if (isLoadingProductPrices) {
    return (
      <div className="w-full px-6 py-8">
        <div className="mb-12 flex flex-col items-center gap-2">
          <Skeleton className="h-10 w-[280px]" />
          <Skeleton className="h-5 w-[400px]" />
          <Skeleton className="mt-3 h-7 w-[200px]" />
        </div>
        <div className="grid gap-12 md:grid-cols-3">
          <Skeleton className="h-[320px] w-full" />
          <Skeleton className="h-[320px] w-full" />
          <Skeleton className="h-[320px] w-full" />
        </div>
      </div>
    );
  }

  if (
    !stripePriceBasicMonthPlan ||
    !stripePriceBasicYearPlan ||
    !stripePriceAdvancedMonthPlan ||
    !stripePriceAdvancedYearPlan ||
    !stripePriceEnterprisePlan
  ) {
    return <p>We're unable to load pricing at this time.</p>;
  }

  const activeBasicPlan = isAnnual
    ? stripePriceBasicYearPlan
    : stripePriceBasicMonthPlan;

  const activeAdvancedPlan = isAnnual
    ? stripePriceAdvancedYearPlan
    : stripePriceAdvancedMonthPlan;

  const currentPlanName = activeSubscriptionPlan?.price?.product?.name;
  const currentPlanBillingInterval =
    activeSubscriptionPlan?.price?.pricingPlanInterval;
  const isSwitchingPlanInterval = (
    currentPlanBillingInterval: string,
    newBillingInterval: string
  ) => {
    return currentPlanBillingInterval !== newBillingInterval;
  };
  return (
    <>
      <div className="px-6 py-8">
        <div className="mb-12 text-center">
          <h2 className="text-3xl font-bold tracking-tight">
            Subscription Plans
          </h2>
          <p className="mt-2 text-muted-foreground">
            Choose the perfect plan for your team's needs
          </p>
          <div className="mt-6 flex items-center justify-center gap-4">
            <Label htmlFor="billing-toggle" className="text-sm">
              Monthly
            </Label>
            <Switch
              id="billing-toggle"
              checked={isAnnual}
              onCheckedChange={setIsAnnual}
            />
            <div className="flex items-center gap-2">
              <Label htmlFor="billing-toggle" className="text-sm">
                Annual
              </Label>
              <Badge variant="secondary">Save 20%</Badge>
            </div>
          </div>
        </div>
        <div className="grid gap-12 md:grid-cols-3">
          {/* Basic Plan */}
          <PricingCard
            name="Basic Plan"
            description="For small teams and startups"
            choicedPlanPriceId={choicedPlanPriceId}
            features={
              stripePriceBasicYearPlan.marketingFeatures as {
                name: string;
              }[]
            }
            isCurrentPlan={currentPlanName === "Basic Plan"}
            isActive={activeSubscriptionPlan?.isActive ?? false}
            actionButtonText="Choose Plan"
            isAnnual={isAnnual}
            isSwitchingPlanInterval={isSwitchingPlanInterval(
              currentPlanBillingInterval ?? "",
              activeBasicPlan.price.pricingPlanInterval
            )}
            price={activeBasicPlan.price.unitAmount}
            currency={activeBasicPlan.price.currency}
            billingInterval={activeBasicPlan.price.pricingPlanInterval}
            onChoosePlan={() =>
              handleGoToSubscriptionCheckoutSession(
                activeBasicPlan.price.unitAmount,
                activeBasicPlan.price.priceId
              )
            }
          />
          {/* Advanced Plan */}
          <PricingCard
            name={activeAdvancedPlan.name}
            choicedPlanPriceId={choicedPlanPriceId}
            description={activeAdvancedPlan.description}
            features={
              activeAdvancedPlan.marketingFeatures as {
                name: string;
              }[]
            }
            isCurrentPlan={currentPlanName === "Advanced Plan"}
            isActive={activeSubscriptionPlan?.isActive ?? false}
            actionButtonText="Choose Plan"
            isAnnual={isAnnual}
            isSwitchingPlanInterval={isSwitchingPlanInterval(
              currentPlanBillingInterval ?? "",
              activeAdvancedPlan.price.pricingPlanInterval
            )}
            price={activeAdvancedPlan.price.unitAmount}
            currency={activeAdvancedPlan.price.currency}
            billingInterval={activeAdvancedPlan.price.pricingPlanInterval}
            onChoosePlan={() =>
              handleGoToSubscriptionCheckoutSession(
                activeAdvancedPlan.price.unitAmount,
                activeAdvancedPlan.price.priceId
              )
            }
            isMostPopular={true}
          />

          <PricingCard
            name="Enterprise Plan"
            description="Custom pricing for large teams"
            features={
              stripePriceEnterprisePlan.marketingFeatures as {
                name: string;
              }[]
            }
            isCurrentPlan={currentPlanName === "Enterprise Plan"}
            choicedPlanPriceId={choicedPlanPriceId}
            actionButtonText="Contact us"
            isAnnual={isAnnual}
            isSwitchingPlanInterval={false}
            price={-1}
            onChoosePlan={() => window.open("/custom", "_blank")}
          />
        </div>
      </div>
      <NeedAdminForUpgradeModal
        isOpen={showNeedAdminModal}
        onClose={() => setShowNeedAdminModal(false)}
      />
      <UpdateActivePlanConfirmationModal
        isAnnual={isAnnual}
        isOpen={showConfirmationPlanModal && confirmChangesData.state !== null}
        confirmChangesData={confirmChangesData.state}
        onClose={() => {
          setChoicedPlanPriceId(null);
          setShowConfirmationPlanModal(false);
        }}
        handleSubscriptionUpdate={handleSubscriptionUpdate}
        isUpdatingPlan={isUpdatingPlan}
      />

      <DeleteContactsModal
        isOpen={showDeleteContactsModal.isOpen}
        newPriceTotalContacts={showDeleteContactsModal.newPriceTotalContacts}
        currentContactCount={showDeleteContactsModal.currentContactCount}
        onOpenChange={(open: boolean) => {
          setShowDeleteContactsModal({
            ...showDeleteContactsModal,
            isOpen: open,
          });
          setChoicedPlanPriceId(null);
        }}
      />
    </>
  );
}
