"use client";

import { useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";
import useInvite from "~/components/LandingPages/useInvite";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import FreeTrialExpired from "~/components/LandingPages/FreeTrialExpired";
import CreateTeam from "~/components/LandingPages/CreateTeam";
import { api, type RouterOutputs } from "~/server/trpc/react";

export type TeamAccountWithStripeSubscription =
  RouterOutputs["teamAccount"]["getActiveTeamAccountUserTeamAccounts"][number];

interface TeamAccountContextType {
  teamAccounts: TeamAccountWithStripeSubscription[];
  activeTeamAccount: TeamAccountWithStripeSubscription;
  setTeamAccounts: () => Promise<
    TeamAccountWithStripeSubscription | undefined | null
  >;
  setActiveTeamAccount: (
    teamAccount: TeamAccountWithStripeSubscription
  ) => TeamAccountWithStripeSubscription | undefined | null;
  currentUserRole: string | null;
}

const TeamAccountContext = createContext<TeamAccountContextType | null>(null);

export function useTeamAccount() {
  return useContext(TeamAccountContext) as TeamAccountContextType;
}

export function TeamAccountProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [activeTeamAccount, setActiveTeamAccount] =
    useState<TeamAccountWithStripeSubscription | null>(null);
  const [loadingActiveTeamAccount, setLoadingActiveTeamAccount] =
    useState(true);
  const handleSetActiveTeamAccount = (
    teamAccount: TeamAccountWithStripeSubscription
  ): TeamAccountWithStripeSubscription | null | undefined => {
    localStorage.setItem("activeTeamAccountId", teamAccount.id);

    setActiveTeamAccount(teamAccount);
    return teamAccount;
  };
  const { teamAccountUser } = useTeamAccountUser();

  const {
    data: teamAccounts,
    refetch,
    status,
  } = api.teamAccount.getActiveTeamAccountUserTeamAccounts.useQuery(
    {
      teamAccountUserId: teamAccountUser?.id || "",
    },
    { refetchOnWindowFocus: true }
  );

  useEffect(() => {
    const firstTeamAccount = teamAccounts?.[0];
    if (status === "success") {
      if (firstTeamAccount) {
        if (!activeTeamAccount) {
          const localStorageId = localStorage.getItem("activeTeamAccountId");
          const localStorageTeamAccount = teamAccounts.find(
            (teamAccount) => teamAccount.id === localStorageId
          );
          if (localStorageTeamAccount) {
            handleSetActiveTeamAccount(localStorageTeamAccount);
          } else {
            handleSetActiveTeamAccount(firstTeamAccount);
          }
        }
      }
      setLoadingActiveTeamAccount(false);
    }
  }, [teamAccounts]);

  const setTeamAccounts = async (): Promise<
    TeamAccountWithStripeSubscription | null | undefined
  > => {
    const data = await refetch();

    const currentTeamAccount = data?.data?.find((teamAccount) => {
      return teamAccount.id === activeTeamAccount?.id;
    });
    if (currentTeamAccount) {
      const activeTeamAccount = handleSetActiveTeamAccount(currentTeamAccount);
      return activeTeamAccount;
    }
  };
  const { data: session } = useSession();
  useEffect(() => {
    if (typeof refetch === "function") {
      refetch().catch((err) => console.error(err, "TeamAccountProvider.tsx2"));
    }
  }, [refetch, session]);

  useInvite(setTeamAccounts);

  if (!activeTeamAccount && status === "success" && !loadingActiveTeamAccount) {
    return <CreateTeam setTeamAccounts={setTeamAccounts} />;
  }

  if (!teamAccountUser || !activeTeamAccount) return null;
  const currentUserRole =
    activeTeamAccount.ownerId === teamAccountUser?.id
      ? "owner"
      : activeTeamAccount.adminIds.includes(teamAccountUser?.id)
        ? "admin"
        : "member";
  if (!teamAccounts) return null;
  const teamAccountsContextValue: TeamAccountContextType = {
    teamAccounts,
    setTeamAccounts,
    activeTeamAccount: activeTeamAccount as TeamAccountWithStripeSubscription,
    setActiveTeamAccount: handleSetActiveTeamAccount,
    currentUserRole,
  };

  const isSubscriptionActive = activeTeamAccount.stripeSubscription?.isActive;

  return (
    <TeamAccountContext.Provider value={teamAccountsContextValue}>
      {isSubscriptionActive || teamAccountUser?.role === "admin" ? (
        children
      ) : (
        <FreeTrialExpired />
      )}
    </TeamAccountContext.Provider>
  );
}
